

@media screen and (max-width:991px) {
    .disktop{
        display: none;
    }
}
@media (min-width:992px) {
    .mobile{
        display: none;
    }
}