.Login_bg {
    background-size: cover;
    /* background-image: url(/latestassets1/img/section06.png) !important;*/
    /* background-repeat: no-repeat; */
    background-attachment: fixed;
    background: linear-gradient(32deg, rgb(0 5 56) 50%, rgb(255 0 198) 100%) !important;
    height: 110vh;
    padding: 0 !important;
    margin: 0 !important;
}


.modal {
    display: none;
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.modal-content {
    background-color: #02064b;
    margin: auto;
    padding: 20px 10px;
    /* border: 1px solid fff; */
    width: 300px;
    border-radius: 6px;
}
.boxset {
    color: #fff;
}
.btn_Model{
    padding: 10px 10px;
    border-radius: 2.4rem;
    display: inline-block;
    cursor: pointer;
    transition: .2s linear;
    color: #fff;
    text-decoration: none;
    font: 600 1.7rem "Mukta Vaani", sans-serif;
    border-width: 0;
    margin-bottom: 0.5rem;
    margin-top: 10px;
    width: 100%;
    max-width: 13rem;
    box-shadow: 2px 3px 2px rgba(166, 165, 165, .16);
    background: #1289e3;
}