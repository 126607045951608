.intro-area-2 .bg-wrapper::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url"Assets/images/bg5.png";
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left top;
    opacity: .98;
    content: "";
  }