.tablebtn {
    border-radius: 0 20px;
    margin: 0 5px;
    color: #fff;
    border-color: var(--primary-bg-color);
    background: #FF06B7;
    font-weight: 700;

}

.bg-pp {
    background: #FF06B7;
    border-radius: 7px;
    color: #fff;
    font-weight: 600;
}

/* .Table_Buttons {
    overflow-x: scroll;
} */



.px-6 {
    padding-right: 2.2rem !important;
    padding-left: 2.2rem !important;
}

.Table_Buttons {
    margin-left: 3px !important;
    margin-top: 5px;
    margin-bottom: 5px;
    justify-content: center;
}



@media only screen and (max-width: 1000px) {
    .table_tr {
        line-height: 1.5 !important;
    }

    td {
        padding: 0.5rem 0.2rem;
        color: white;
        font-size: 1.2rem;
        text-align: center;
    }
}